






















import { isNull } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { epochElapsedTime } from 'shared/util/formatTime'

@Component({ components: {} })
export default class ServicePerformance extends Vue {
  @Prop() service!: any
  background = 'white'

  val(prop: string, value: number) {
  return (prop === 'uptime') ? epochElapsedTime(value) : typeof(value) === 'object' ? JSON.stringify(value) : value
  }

  whole(s: string) {
	return parseFloat(s).toFixed(0)
  }

  get pm2EnvKeys(): string[] {
	const keys = this.service ? Object.keys(this.service.pm2_env.axm_monitor).sort() : []
 return keys.filter(k => !['Heap Size', 'Heap Usage', 'Used Heap Size'].includes(k))
  }

  @Watch('service', { deep: true })
  serviceUpdating(newVal) {
	this.background = 'light-gray'
	setTimeout(() => { this.background = 'white' }, 1000)
  }
}
