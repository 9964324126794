



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { bus } from 'shared/state/Bus'
import { getCookie } from 'shared/util/cookie'
import { epochElapsedTime } from 'shared/util/formatTime'
const axios = require('axios').default

@Component({ components: {  } })
export default class Pm2Services extends Vue {
@Prop() services!: any[]
@Prop() server!: any


fields = [
	{key: 'action', label: '', sortable: false, class: 'text-center'},
	{key: 'name', class: 'text-left'},
	{key: 'id', label: 'id (PM / Win)', formatter: (value, key, item) => `${item.pm_id} / ${item.pid}`},
	{key: 'mem', formatter: (value, key, item) => (parseInt(item.monit.memory, 10) / 1048576).toFixed(0) + 'Mb' },
	{key: 'status', formatter: (value, key, item) => item.pm2_env.status},
	{key: 'uptime', formatter: (value, key, item) => epochElapsedTime(item.pm2_env.pm_uptime) , class: 'text-left'},
	{key: 'version', formatter: (value, key, item) => this.version(value, key, item )}
]
updated = new Date()
timer
competitions: any[] = []
instances: any[] = []
filterVal = ''
output = ''
restartTitle = ''
checkingHPport = 0
checkingErgport = 0
selected: string = ''
serviceId = ''

get serviceDetail() {
	return this.services.find(s => s.id === this.serviceId)
}
version(value, key, item) {
   return item.name.includes('API') ? item.api_version :
   item.name.includes('Monitor') ? item.monitor_version : ''
}

dialog(item) {
		this.serviceId = item.id
		this.$root.$emit('bv::show::modal', 'serviceStats')
		bus.$emit('service_detail', this.server.url, this.serviceDetail)
	}
  restart(item: any) {
	const athletes = item.hp_clients + item.mobile_clients
	const p = athletes > 1
	this.$bvModal
	.msgBoxConfirm(
		`Are you sure you want to restart ${item.name}?`,
		{
			size: 'sm',
			buttonSize: 'lg',
			okVariant: 'success',
			cancelVariant: 'danger',
			okTitle: 'Yes',
			cancelTitle: 'No',
			bodyClass: 'bdy',
			footerClass: 'p-2',
			hideHeaderClose: false,
			centered: true,
		}
	)
	.then(async (response) => {
		if (response) {
			const result = await axios
						.get(this.server.url + `/instance/restart`, {
							headers: {
								Authorization: `Bearer ${getCookie('logbooktoken')}`
							}
						}).then(result => {
							if (!result?.data?.success) {
								this.$bvModal.msgBoxOk('Restart failed. ')
							}
						})
		}
	})
  }
  online(v) {
	return v === 'online' ? 'green' : 'red'
  }
  @Watch('serviceDetail', { immediate: true })
	detailCodeChanged(newVal) {
		const code = newVal?.code || newVal
		if (newVal) {
			bus.$emit('service_detail', this.server.url, this.serviceDetail)
		}
	}
}

