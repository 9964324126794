






import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import CustomEditor from 'shared/ckeditor5-35/build/ckeditor.js'

Vue.use( CKEditor )

@Component({ components: { CKEditor } })
export default class Announcement extends Vue {
	@Prop() value!: string
	cb = false
	editor = CustomEditor
	editorData = ''

	editorConfig = {}

	created() {
		if(typeof(this.value) === 'string') { this.editorData = this.value }
	}
	@Watch('value', { immediate: true })
	valueChanged(e) {
		this.editorData = this.value
		this.cb = !!this.value
	}
	@Watch('editorData', { immediate: true })
	editorDataChanged(e) {
		if(typeof(e) === 'string') { this.$emit('input', this.editorData) }
	}
}
