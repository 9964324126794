

































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { bus } from 'shared/state/Bus'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

@Component({ components: {} })
export default class Message extends Vue {
	@Prop() competition!: string
	@Prop() selected!: string
	@Prop() options!: any[]
	@Prop() serverMonitor!: any

	isSbVisible: boolean = false
	text = ''
	action: any = {}
	get messagesByCompetition() {
		if(!this.messages) { return [] }
		return this.messages.filter(m => m.competition && m.competition === this.selected) || []
	}
	hyphenize(word: string) {
	  return word.replace('.', '-')
  	}
	selectChange() {
		this.competition = this.selected || ''
		this.showSb()
		this.action = {
				action: 'notify',
				detail: {
					competition: this.selected,
					message: '',
				}
			}
	}
	send() {
		this.action = {
				action: 'notify',
				detail: {
					competition: this.selected,
					message: this.text,
				}
			}
		this.serverMonitor.monitor.monitor.send(this.action)
		this.text = ''
		setTimeout(() => { this.scrollSBBottom() }, 100)
}
	showSb(servername?) {
		if(servername && servername !== this.serverMonitor.server.name) { return }
		if(this.isSbVisible) { return }
		this.$root.$emit('bv::toggle::collapse', this.msgBoxId)
	}
	hideSb() {
		if(!this.isSbVisible) { return }
		this.$root.$emit('bv::toggle::collapse', this.msgBoxId)
	}
	SbChange(visible: boolean) {
		this.isSbVisible = visible
	}
	get msgBoxId() {
		return 'sb-' + this.hyphenize(this.serverMonitor.server.name)
	}

	scrollSBBottom() {
		const box = document.getElementById(this.msgBoxId)?.getElementsByClassName('b-sidebar-body')[0]
		if(box) {
			box.scrollTop = box.scrollHeight
		}
	}
	get messages() {
		return this.serverMonitor.monitor.monitor.messages
	}
	created() {
		bus.$on('showSB', this.showSb)
	}
	beforeDestroy() {
		bus.$off('showSB', this.showSb)
	}
}
