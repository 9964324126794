























































import { isNull } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { epochElapsedTime } from 'shared/util/formatTime'
import ServicePerformance from './ServicePerformance.vue'

@Component({ components: { ServicePerformance } })
export default class ServiceDetail extends Vue {
  @Prop() service!: any
  @Prop() serverUrl!: string
  background = 'white'

  val(prop: string, value: number) {
  return (prop === 'uptime') ? epochElapsedTime(value) : typeof(value) === 'object' ? JSON.stringify(value) : value
  }
  whole(s: string) {
	return parseFloat(s).toFixed(0)
  }

  get serverAbbr() {
  if (!this.serverUrl) { return '' }
  const match = this.serverUrl.toString().match(/\w+/gi)
  return match ? match[1].toUpperCase() || '' : ''
  }

  get serviceStatKeys(): string[] {
  const keys = this.service ? Object.keys(this.service).sort() : []
  return keys.filter(k => !['c2', 'code', 'port', 'monit', 'pm2_env', 'name'].includes(k))
  }

  get pm2EnvKeys(): string[] {
	const keys = this.service ? Object.keys(this.service.pm2_env.axm_monitor).sort() : []
 return keys.filter(k => !['Heap Size', 'Heap Usage', 'Used Heap Size'].includes(k))
  }

  get statsHtml() {
	return this.service?.code || ''
  }
  log(l: string, option?: string) {
  switch (l) {
  case 'api': window.open(`${this.serverUrl}/download/logs/api/${option}`, `_blank`)
				          break
  case 'monitor': window.open(`${this.serverUrl}/download/logs/monitor`, `_blank`)
					             break
  case 'daemon': window.open(`${this.serverUrl}/download/logs/daemon`, `_blank`)
					            break
	}
  }

  @Watch('service', { deep: true })
  serviceUpdating(newVal) {
	this.background = 'light-gray'
	setTimeout(() => { this.background = 'white' }, 1000)
  }
}
