































import { Component, Prop, Vue } from 'vue-property-decorator'
// import ServerMonitor from 'shared/components/ServerMonitor.vue'
import InstancesTable from './InstancesTable.vue'
import Memory from './Memory.vue'
import Disk from './Disk.vue'
import Instances from './Instances.vue'
import Config from './Config.vue'
import { Server } from 'https'
import Pm2Services from './Pm2Services.vue'


@Component({ components: { Memory, Disk, Instances, InstancesTable, Config, Pm2Services } })
export default class ServerArea extends Vue {
	@Prop() server!: any
	@Prop() serverMonitor!: any
	services: any = []

	servicesChanged(e, apiVersion, monitorVersion) {
		e.forEach(s => {
			s.name = s.name.toString().replace('@jessety/pm2-logrotate', 'PM2 Windows Service')
			if(!s.api_version) { s.api_version = apiVersion || '0.0' }
			if(!s.monitor_version) { s.monitor_version = monitorVersion || '0.0'}
		})
		this.services = e
	}
}

