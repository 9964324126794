










import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import WebServer from './WebServer.vue'

@Component({ components: { WebServer } })
export default class WebServers extends Vue {
	@Prop() monitorServers!: any[]
	@Prop() value!: string

	webServers = ['PROD', 'DEV']

}
