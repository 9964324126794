











import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { MemoryStatusType, CpuStatusType } from 'shared/types'
import { formatBytes, addData, removeData, createChart } from 'shared/util/charts'
import { bus } from 'shared/state/Bus'

interface MemoryStatusWrapper {
	memory_status: MemoryStatusType,
	socket: string
}

interface CpuStatusWrapper {
	cpu_status: CpuStatusType,
	socket: string
}

@Component({ components: {  } })
export default class Memory extends Vue {
	@Prop() server!: any

	// monitor: MonitorClass = this.serverMonitor.monitor.monitor ? this.serverMonitor.monitor.monitor : null
	loadpct

	data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	data1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	labels = ['']
	Chart

	memStat: any = 0
	cpuStat: any = 0
	// tslint:disable-next-line:no-bitwise
	chartName = 'memory-chart-' + ( ~~(Math.random() * 1000) ).toString()

	created() {
		bus.$on('memory_status', this.handle_memoryStatus)
		bus.$on('cpu_status', this.handle_cpuStatus)
	}

	beforeDestroy() {
		bus.$off('memory_status', this.handle_memoryStatus)
		bus.$off('cpu_status', this.handle_cpuStatus)
	}

	handle_memoryStatus(status: MemoryStatusType, socket: string) {
		if(socket === this.server.wss) {
			this.memStat = status
			addData(this.Chart, this.labels, status.usedpct)
			removeData(this.Chart)
		}
	}

	handle_cpuStatus(cpu_status: CpuStatusType, socket: string) {
		if(socket === this.server.wss ) {
			this.cpuStat = cpu_status
			addData(this.Chart, this.labels, cpu_status.loadpct, 1)
			removeData(this.Chart, 1)
		}
	}

	mounted() {
		this.Chart = createChart(this.chartName, this.chartData)
	}

	get total() {
		return this.memStat ? formatBytes(this.memStat.total) : ''
	}

	get free() {
		return this.memStat ? formatBytes(this.memStat.free) : ''
	}

	refresh() {
		this.data = []
		for (let i = 0; i < 10; i ++ ) {
			this.data.push(Math.random() * 10)
		}
		createChart(this.chartName, this.chartData)
	}

	get chartData() {
		const d = {
		type: 'line',
		data: {
			labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
			datasets: [
				{
					// another line graph
					label: 'Memory Usage %',
					data: this.data,
					backgroundColor: [
						'#EDE275aa' // Kacki
					],
					borderColor: ['#EDE275'],
					borderWidth: 3,
				},
				{
					// another line graph
					label: 'CPU Usage %',
					data: this.data1,
					backgroundColor: [
						'rgba(71, 183,132,.5)' // Green
					],
					borderColor: ['#47b784'],
					borderWidth: 3,
				}
			]
		},
		options: {
			responsive: true,
			lineTension: 1,
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
							padding: 25,
							maxTicksLimit: 20,
							stepSize: 5,
							suggestedMax: 100
						}
					}
				]
			}
		}
	}
		return d
	}
}

