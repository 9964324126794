





































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Chart from 'chart.js'
import { planetChartData, doughnutChartData } from 'shared/util/chart-data.js'
import { setCookie, getCookie } from 'shared/util/cookie'
import { bus } from 'shared/state/Bus'
import ServerMonitor from 'shared/components/ServerMonitor.vue'
import { RemoteControlClass } from 'shared/state/RemoteControl'
import { ServerModule } from 'shared/state/Server'
import Message from './Message.vue'
import { epochElapsedTime } from 'shared/util/formatTime'
import { ErgModule } from 'shared/state/Erg'
const axios = require('axios').default


@Component({ components: { Message } })
// @Component({ components: { Message } })
export default class InstancesTable extends Vue {
	@Prop() server!: any
	@Prop() serverMonitor!: any

	state = ''
	restarting = {}
	competition = ''
	instance = {}

	fields = [
		{ key: 'action', label: '', sortable: false, class: 'text-center' },
		{ key: 'organizer', label: 'Organizer', sortable: true, class: 'text-center' },
		{ key: 'pretty_code', label: 'competition', sortable: true, class: 'text-center' },
		{ key: 'uptime', label: 'Up Time', sortable: true, class: 'text-center' },
		{ key: 'est_finish', label: 'Est. Finish', sortable: true, class: 'text-center' },
		{ key: 'race_status', label: 'status', sortable: true, class: 'text-center' },
		{ key: 'hp_clients', label: 'HP Clients', sortable: true, class: 'text-center' },
		{ key: 'erg_clients', label: 'ER Clients (M/D)', sortable: true, class: 'text-center' },
		// {key: 'hp_port', label: 'HP Port', sortable: true, class: 'text-center'},
		// {key: 'erg_port', label: 'ER Port', sortable: true, class: 'text-center'},
		{ key: 'ports', label: 'Ports (HP/ER)', sortable: false, class: 'text-center break-word' },

		{ key: 'hp_ids', label: 'HP IDs (PM/Win)', sortable: true, class: 'text-center' },
		{ key: 'erg_ids', label: 'ER IDs (PM/Win)', sortable: true, class: 'text-center' },
		{ key: 'hp_mem', label: 'HP Mem', sortable: true, class: 'text-center' },
		{ key: 'erg_mem', label: 'Erg Mem', sortable: true, class: 'text-center' },
		{ key: 'detail', label: '', sortable: false, class: 'text-center' },
		{ key: 'status', label: 'pm status', sortable: true, class: 'text-center' },
		{ key: 'versions', label: 'Versions (HP/Erg)', sortable: true, class: 'text-center' },


	]
	items: any[] = []
	updated = new Date()
	timer
	competitions: any[] = []
	instances: any[] = []
	filterVal = ''
	output = ''
	restartTitle = ''
	checkingHPport = 0
	checkingErgport = 0
	selected: string = ''
	ApiVersion = '0.0'
	detailCode = ''

	get store() {
		return this.serverMonitor.monitor.monitor.store
	}
	get users() {
		return this.serverMonitor.monitor.monitor.users
	}

	get options() {
		return this.competitions.filter(c => c.organizer !== 'disconnected').map(c => ({ value: c.code, text: c.pretty_code }))
	}

	showSb() {
		bus.$emit('showSB', this.serverMonitor.server.name)
	}

	isAutomated(code) {
		const c = code.toString().replace(/[-]/g, '')
		const autos = this.serverMonitor.monitor.monitor.settings.AUTOMATED_COMPETITIONS
		return autos ? autos.includes(c) : false
		// legacy ['5591052823', '4270364121'].includes(code)
	}

	hyphenize(word: string) {
		return word.replace('.', '-')
	}

	strip(word: string) {
		return word.replace(/[-]/g, '')
	}

	liveViewLink(value: string) {
		return `https://live.ergrace.com/race/${this.strip(value)}/#/scroll`
	}
	get monitorVersion() {
		return this.serverMonitor.monitor.monitor.version
	}

	async created() {
		bus.$on('set_competition_detail', this.setCompetitionDetail)
		this.update()
		this.timer = setInterval(async () => {
			this.update()
			// the following line can be removed once all servers upgraded > v1.28.0
			if (this.monitorVersion === '<1.31.0') {
				this.serverMonitor.monitor.monitor.send({ action: 'get_store' })
			}
			if (this.monitorVersion >= '1.33.0') {
				this.serverMonitor.monitor.monitor.send({ action: 'get_users' })
			}
		}, 5000)

	}
	beforeDestroy() {
		bus.$off('set_competition_detail', this.setCompetitionDetail)
		clearTimeout(this.timer)
	}

	raceStatus(port: number) {
		const erg = this.serverMonitor.ergs.find(e => e.erg_port === port)
		return erg ? erg.ergrace.erg.status : '?'
	}

	erg(port: number) {
		return this.serverMonitor.ergs.find(e => e.erg_port === port)
	}
	estFinishTime(port: number) {
		const erg = this.erg(port)
		const timeEstimate = erg ? erg.ergrace.erg.estFinishTime : -1
		return `${timeEstimate === -1 ? '' : timeEstimate < 1 ? '< 1m' : timeEstimate.toFixed(0) + 'm'} `
	}

	hp(port: number) {
		return this.serverMonitor.hps.find(e => e.hp_port === port)
	}

	connections(port: number) {
		const erg = this.erg(port)
		return erg ? erg.ergrace.remote_control.RemoteErg.connections : { displayClients: '0', mobileClients: '0' }
	}

	HPMobileClients(port: number) {
		const hp = this.hp(port)
		return hp ? hp.hp.remote_control.RemoteErg.connections.mobileClients : '0'
	}

	async update() {
		const temp = await this.getData()
		this.items = []
		this.items = temp
		this.updated = new Date()
		const tds = document.getElementsByTagName('td')
		for (const element of tds) {
			element.className = 'align-middle'
		}
		bus.$emit('got_competition_instances', this.server, this.items)
	}

	message(item: any) {
		if (!item?.code) { return }
		this.competition = item.code
		this.selected = item.code
		this.showSb()
	}

	shutDown(item: any) {
		const athletes = item.hp_clients + item.mobile_clients
		const p = athletes > 1
		this.$bvModal
			.msgBoxConfirm(
				`Are you sure you want to shut down Holding Pen and Ergrace for Competition ${item.pretty_code}?` +
				(athletes ? ` There ${p ? 'are' : 'is'} ${athletes} athlete${p ? 's' : ''} attached. ` : ``),
				{
					size: 'sm',
					buttonSize: 'lg',
					okVariant: 'success',
					cancelVariant: 'danger',
					okTitle: 'Yes',
					cancelTitle: 'No',
					bodyClass: 'bdy',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				}
			)
			.then(async (response) => {
				if (response && item.code) {
					const result = await axios
						.get(this.server.url + `/instance/delete/${item.code}`, {
							headers: {
								Authorization: `Bearer ${getCookie('logbooktoken')}`
							}
						})
					if (!result?.data?.success) {
						this.$bvModal.msgBoxOk('Shut down failed. ' + result.data.data)
						return
					}
					const m = 'Competition was shut down by Concept2. If you would like to resume, refresh your screen.'
					const action = {
						action: 'notify',
						detail: {
							competition: item.code,
							message: m,
						}
					}
					this.serverMonitor.monitor.monitor.send(action)
					// this.messages.push({sent: m, competition: item.code })
				}
			})
	}
	restart(item: any) {
		const athletes = item.hp_clients + item.mobile_clients
		const p = athletes > 1
		this.$bvModal
			.msgBoxConfirm(
				`Are you sure you want to restart Holding Pen and Ergrace for Competition ${item.pretty_code}?` +
				(athletes ? ` There ${p ? 'are' : 'is'} ${athletes} athlete${p ? 's' : ''} attached. ` : ``),
				{
					size: 'sm',
					buttonSize: 'lg',
					okVariant: 'success',
					cancelVariant: 'danger',
					okTitle: 'Yes',
					cancelTitle: 'No',
					bodyClass: 'bdy',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				}
			)
			.then(async (response) => {
				if (response && item.code) {
					const m = 'Competition was restarted by Concept2.'
					const action = {
						action: 'notify',
						detail: {
							competition: item.code,
							message: m,
						}
					}
					this.serverMonitor.monitor.monitor.send(action)
					// this.messages.push({sent: m, competition: item.code })
					this.restartTitle = 'Restarting ' + item.pretty_code
					this.checkingHPport = item.hp_port
					this.checkingErgport = item.erg_port
					this.output = ''
					this.$bvModal.show('modal-output-' + this.hyphenize(this.server.name))
					this.restartHP()
				}
			})
	}
	safeParseInt(val) {
		try {
			return parseInt(val, 10)
		} catch {
			return 0
		}
	}

	get HPconnected() {
		if (this.checkingHPport === 0) { return 'unknown' }
		return this.hp(this.checkingHPport).hp.server.state
	}

	get ErgConnected() {
		if (this.checkingErgport === 0) { return 'unknown' }
		return this.erg(this.checkingErgport).ergrace.server.state
	}

	restartHP() {
		this.state = 'HP restarting'
		const hp = this.hp(this.checkingHPport)
		const hpRc: RemoteControlClass = hp.hp.remote_control
		const hpServer: ServerModule = hp.hp.server
		hpRc.remoteErgAction('exit')
	}
	restartErg() {
		this.state = 'Erg restarting'
		const erg = this.erg(this.checkingErgport)
		const ergRc: RemoteControlClass = erg.ergrace.remote_control
		const ergServer: ServerModule = erg.ergrace.server
		ergRc.remoteErgAction('exit')
	}

	async getData() {
		axios.get(this.server.url + `/instance/version`).then((resp) => {
			this.ApiVersion = resp.data.version
		})
		return await axios.get(this.server.url + `/instance/v`,
			{
				headers: { Authorization: `Bearer ${getCookie('logbooktoken')}` }
			})
			.then((response) => {
				const instanceData = response.data.data.filter(i =>
					!i.name.toString().includes('Service' ) && !i.name.toString().includes('pm2-logrotate')
				)
				this.$emit('servicesChanged', response.data.data.filter(i => i.name.toString().includes('Service' ) || i.name.toString().includes('pm2-logrotate')), this.ApiVersion, this.monitorVersion)
				this.competitions = []
				this.instances = instanceData

				if (instanceData && response.data.success) {
					instanceData.forEach(i => {

						const isHP = i.name.includes('pen')
						const c = /\d+/.exec(i.name)
						const code = c ? c[0] : ''
						const p = /port_\d+/.exec(i.name)
						const port = p ? parseInt(p[0].replace('port_', ''), 10) : 0
						const o: any = {
							code,
							pretty_code: `${code.slice(0, 3)}-${code.slice(3, 6)}-${code.slice(6, 15)}`,
							est_finish: '?'
						}

						if (isHP) {
							o.hp_port = port // i.port
							o.hp_ids = i.pid + ' / ' + i.pm_id // i.id + ' / ' + i.pid
							o.hp_mem = (parseInt(i.monit.memory, 10) / 1048576).toFixed(0) + 'Mb' // i.mem // i.mem
							o.hp_clients = this.safeParseInt(this.HPMobileClients(port))
							const s = this.hp(port)?.hp?.remote_control?.RemoteErg?.HPVersion
							o.hp_version = s ? s : ''
							o.hp_data = i
						} else {
							o.erg_clients = this.connections(port).mobileClients + ' / ' + this.connections(port).displayClients
							o.race_status = this.raceStatus(port)
							o.mobile_clients = this.safeParseInt(this.connections(port).mobileClients)
							o.display_clients = this.safeParseInt(this.connections(port).displayClients)
							o.er_data = i
							o.erg_port = port
							o.erg_pid = i.pm_id
							o.erg_id = i.pid
							o.erg_ids = i.pid + ' / ' + i.pm_id
							o.erg_mem = (parseInt(i.monit.memory, 10) / 1048576).toFixed(0) + 'Mb' // i.mem
							const s = this.erg(port)?.ergrace?.remote_control?.RemoteErg?.ErgVersion
							o.est_finish = this.estFinishTime(port)
							o.erg_version = s ? s : ''
							o.created_at = new Date(i.pm2_env.created_at).toLocaleString()
							o.uptime = epochElapsedTime(i.pm2_env.pm_uptime)
						}
						const foundIndex = this.competitions.findIndex(c => c.code === code)
						if (foundIndex === -1) { this.competitions.push(o) } else {
							this.competitions[foundIndex] = { ...this.competitions[foundIndex], ...o }

							const hp_status = this.competitions[foundIndex].hp_data!.pm2_env.status
							const er_status = this.competitions[foundIndex].er_data!.pm2_env.status

							let status = er_status
							if (hp_status !== 'online') {
								status = 'HP ' + hp_status
							}
							if (er_status !== 'online') {
								status = 'ER ' + er_status
							}
							this.competitions[foundIndex].status = status
						}
						const found = this.competitions.find(c => c.code === code)
						if (found && found.hp_data && found.hp_data.pm2_env.status !== 'online') { found.status = 'HP ' + found.hp_data.pm2_env.status }
						if (found && found.er_data && found.er_data.pm2_env.status !== 'online') { found.status = 'ER ' + found.er_data.pm2_env.status }
						if (found && !found.hp_data) { found.status = 'HP Offline' }
						if (found && !found.er_data) { found.status = 'ER Offline' }
					})
				}
				this.competitions.forEach(c => {
					c.versions = c.hp_version + ' ' + c.erg_version
					const p = this.store.find(s => s.competition === c.code)
					const q = this.users.find(s => s.competition === c.code)
					c.organizer =
						p ? p.first_name + ' ' + p.last_name :
							q ? q.first_name + ' ' + q.last_name :
								'disconnected'
					c.is_connected = !!p
					c.ports = c.hp_port + ' / ' + c.erg_port
				})
				return this.competitions
			})
			.catch((err) => {
				return
			})
	}
	get compDetail() {
		return this.competitions.find(i => i.code === this.detailCode)
	}
	prettify(code: string) {
		return `${code.slice(0, 3)}-${code.slice(3, 6)}-${code.slice(6, 15)}`
	}
	dialog(item) {
		this.detailCode = item.code
		this.$root.$emit('bv::show::modal', 'competitionStats')
		bus.$emit('set_competition_detail', item.code)
		bus.$emit('competition_detail', this.server.url, this.compDetail)
	}

	markup(val, state) {
		const out = state.replace('disconnected', 'shut down')
		return `<div class="${val}">${out}</div>`
	}
	setCompetitionDetail(code) {
		this.detailCode = code
	}

	@Watch('compDetail', { immediate: true })
	detailCodeChanged(newVal) {
		const code = newVal?.code || newVal
		if (newVal) {
			bus.$emit('competition_detail', this.server.url, this.compDetail)
		}
	}
	@Watch('HPconnected', { immediate: true })
	HPconnectedChanged(newVal) {
		if (newVal === 'disconnected') {
			setTimeout(() => {
				this.restartErg()
			}, 6000)
		}
		this.state = 'HP ' + newVal
		if (!['unknown', 'unconnected'].includes(newVal)) { this.output += this.markup(newVal, this.state) }
	}
	@Watch('ErgConnected', { immediate: true })
	ErgConnectedChanged(newVal) {
		this.state = 'Erg ' + newVal
		if (!['unknown', 'unconnected'].includes(newVal)) { this.output += this.markup(newVal, this.state) }
	}
	@Watch('state', { immediate: true })
	stateChanged(newVal) {
		if (newVal === 'Erg disconnected') {
			setTimeout(() => {
				this.hp(this.checkingHPport).hp.server.connect()
				this.erg(this.checkingErgport).ergrace.server.connect()
			}, 6000)
		}
	}
}

